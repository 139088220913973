// nuxt-ui/stores/teamAndPlayerStore.ts

import type { Season } from "~/src/season-cluster/domain/models/01.Season";
import type { PlayerSeasons, SeasonPlayerInfo } from "~/src/player/domain/Player";

export const useTeamAndPlayerStore = defineStore("teamAndPlayerStore", () => {
  const teamLastSeasonPlayed = ref<Season | undefined>();
  const teamSeasonsPlayed = ref<Season[] | undefined>();
  const playerLastSeasonPlayed = ref<SeasonPlayerInfo | undefined>();
  const playerSeasonsPlayed = ref<PlayerSeasons | undefined>();
  const teamId = ref<number | undefined>();
  const playerId = ref<number | undefined>();

  return {
    teamLastSeasonPlayed,
    teamSeasonsPlayed,
    playerLastSeasonPlayed,
    playerSeasonsPlayed,
    teamId,
    playerId,
  };
});
